<template>
  <div>
    <validation-observer :ref="'form-' + index" v-slot="{ invalid }">
      <b-row>
        <b-col cols="12">
          <b-form-group id="documents">
            <label for="documents">Documentos vinculados</label>
            <multiselect
              v-model="form.documents"
              track-by="id"
              label="name"
              :options="documentOptions"
              :close-on-select="false"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :multiple="true"
              placeholder="Consulta"
              class="multiple with-border"
              :limit="documentInputLimit"
              openDirection="bottom"
              :disabled="form.is_general_setting"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="selection" slot-scope="{ values }">
                <div class="d-flex inline-flex">
                  <ItemChip
                    v-for="(val, index) in values"
                    :key="index"
                    removable
                    @remove="removeDocument(val)"
                  >
                    <template slot="text">
                      <p
                        style="
                          text-overflow: ellipsis;
                          overflow: hidden;
                          white-space: nowrap;
                          max-width: 80px;
                        "
                      >
                        {{ val.name }}
                      </p>
                    </template>
                  </ItemChip>
                  <ItemChip
                    v-if="form.documents.length > documentInputLimit"
                    :removable="false"
                  >
                    <template slot="text">
                      <p class="mr-1">
                        +{{ form.documents.length - documentInputLimit }}
                      </p>
                    </template>
                  </ItemChip>
                </div>
              </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-form-group>
            <SimpleSelect
              label="Tipo de impressão"
              v-model="form.format"
              :options="pageFormats"
            />
            <div
              v-if="validate && !form.format"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group>
            <SimpleSelect
              track-by="value"
              label="Orientação"
              v-model="form.orientation"
              :options="orietations"
            />
            <div
              v-if="validate && !form.orientation"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group>
            <SimpleSelect
              track-by="value"
              label="Tamanho do texto"
              v-model="form.font_size"
              :options="fontSizes"
            />
            <div
              v-if="validate && !form.font_size"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group>
            <validation-provider
              name="margem esquerda"
              :rules="{
                required: true,
                max_value: maxVerticalMargin,
                min_value: 0
              }"
              v-slot="{ errors, touched }"
            >
              <label for="marginLeft"
                >Margem esquerda (mm)
                <span :id="`margin-left-${index}`" class="icon-box"
                  ><HelpCircle class="help-icon" />
                  <b-tooltip
                    :target="`margin-left-${index}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    Os valores de margem esquerda estão limitados as 30% da
                    largura da folha
                  </b-tooltip>
                </span>
              </label>
              <b-form-input
                id="marginLeft"
                autocomplete="off"
                v-model="form.margin_left"
                type="number"
                :max="maxHorizontalMargin"
                min="0"
                :class="{ error: touched && errors.length > 0 }"
              />
              <div
                v-if="touched && errors.length > 0"
                class="custom-invalid-feedback"
              >
                {{ errors[0] }}
              </div>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group>
            <validation-provider
              name="margem superior"
              :rules="{
                required: true,
                max_value: maxVerticalMargin,
                min_value: 0
              }"
              v-slot="{ errors, touched }"
            >
              <label for="marginTop"
                >Margem superior (mm)
                <span :id="`margin-top-${index}`" class="icon-box"
                  ><HelpCircle class="help-icon" />
                  <b-tooltip
                    :target="`margin-top-${index}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    Os valores de margem superior estão limitados as 30% da
                    altura da folha
                  </b-tooltip>
                </span>
              </label>
              <b-form-input
                id="marginTop"
                autocomplete="off"
                v-model="form.margin_top"
                type="number"
                :max="maxVerticalMargin"
                min="0"
                :class="{ error: touched && errors.length > 0 }"
              />
              <div
                v-if="touched && errors.length > 0"
                class="custom-invalid-feedback"
              >
                {{ errors[0] }}
              </div>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group>
            <validation-provider
              name="margem direita"
              :rules="{
                required: true,
                max_value: maxVerticalMargin,
                min_value: 0
              }"
              v-slot="{ errors, touched }"
            >
              <label for="marginRight"
                >Margem direita (mm)
                <span :id="`margin-right-${index}`" class="icon-box"
                  ><HelpCircle class="help-icon" />
                  <b-tooltip
                    :target="`margin-right-${index}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    Os valores de margem direita estão limitados as 30% da
                    largura da folha
                  </b-tooltip>
                </span>
              </label>
              <b-form-input
                id="marginRight"
                autocomplete="off"
                v-model="form.margin_right"
                type="number"
                :max="maxHorizontalMargin"
                min="0"
                :class="{ error: touched && errors.length > 0 }"
              />
              <div
                v-if="touched && errors.length > 0"
                class="custom-invalid-feedback"
              >
                {{ errors[0] }}
              </div>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group>
            <validation-provider
              name="margem inferior"
              :rules="{
                required: true,
                max_value: maxVerticalMargin,
                min_value: 0
              }"
              v-slot="{ errors, touched }"
            >
              <label for="marginBottom"
                >Margem inferior (mm)
                <span :id="`margin-bottom-${index}`" class="icon-box"
                  ><HelpCircle class="help-icon" />
                  <b-tooltip
                    :target="`margin-bottom-${index}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    Os valores de margem inferior estão limitados as 30% da
                    altura da folha
                  </b-tooltip>
                </span>
              </label>
              <b-form-input
                id="marginBottom"
                autocomplete="off"
                v-model="form.margin_bottom"
                type="number"
                :max="maxVerticalMargin"
                min="0"
                :class="{ error: touched && errors.length > 0 }"
              />
              <div
                v-if="touched && errors.length > 0"
                class="custom-invalid-feedback"
              >
                {{ errors[0] }}
              </div>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="3">
          <div class="checkbox-wrapper">
            <Check v-model="form.hide_brand" />
            <div>Ocultar Logo</div>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="checkbox-wrapper">
            <Check v-model="form.hide_address" />
            <div>Ocultar endereço</div>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="checkbox-wrapper">
            <Check v-model="form.hide_professional_signature" />
            <div>Ocultar Assinatura</div>
          </div>
        </b-col><b-col cols="3">
          <div class="checkbox-wrapper">
            <Check v-model="form.hide_professional_name" />
            <div>Ocultar nome do profissional</div>
          </div>
        </b-col>
      </b-row>

      <hr />
      <b-row>
        <b-col cols="10">
          <label for="patientInfo"> Exibir informações do paciente </label>
        </b-col>
        <b-col cols="2" class="d-flex justify-content-end">
          <toggle-button
            id="patientInfo"
            v-model="form.show_patient_info"
            :sync="true"
            :height="24"
            :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
          />
        </b-col>
      </b-row>
      <b-row v-if="form.show_patient_info">
        <b-col cols="3">
          <div class="checkbox-wrapper">
            <Check v-model="form.show_patient_name" :disabled="true" />
            <div class="disabled">Nome</div>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="checkbox-wrapper">
            <Check v-model="form.show_patient_cpf" />
            <div>CPF</div>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="checkbox-wrapper">
            <Check v-model="form.show_patient_rg" />
            <div class="disabled">RG</div>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="checkbox-wrapper">
            <Check v-model="form.show_patient_health_plan" />
            <div>Convênio</div>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="checkbox-wrapper">
            <Check v-model="form.show_patient_age" />
            <div class="disabled">Idade</div>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="checkbox-wrapper">
            <Check v-model="form.show_patient_gender" />
            <div>Sexo</div>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="checkbox-wrapper">
            <Check v-model="form.show_patient_medical_record" />
            <div class="disabled">N. prontuário</div>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="checkbox-wrapper">
            <Check v-model="form.show_exam_date" />
            <div>Data do exame</div>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="checkbox-wrapper">
            <Check v-model="form.show_patient_address" />
            <div>Endereço</div>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="checkbox-wrapper">
            <Check v-model="form.show_values" />
            <div>Exibir Valores</div>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="checkbox-wrapper">
            <Check v-model="form.show_percentage" />
            <div>Exibir Percentual</div>
          </div>
        </b-col>
      </b-row>

      <hr />
      <b-row>
        <b-col cols="12" class="d-flex justify-content-end">
          <div class="inline-flex">
            <Trash
              class="icon trash"
              v-if="!form.is_general_setting"
              @click="handleOnDelete()"
            />
            <Printer class="icon printer" @click="$emit('createPreview')" />
          </div>
          <div class="btn-wrapper">
            <b-button
              class="save-button"
              variant="primary"
              :disabled="loading || !isValid() || invalid"
              @click="handleOnSave"
            >
              <b-spinner
                small
                v-if="loading"
                variant="light"
                type="grow"
              ></b-spinner>
              Salvar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
    <DeleteModal
      type="pré-definição de impressão"
      article="a"
      :description="form.title"
      :confirmedDelete="confirmDelete"
    />
  </div>
</template>

<script>
export default {
  components: {
    Check: () => import('@/components/General/Check'),
    SimpleSelect: () => import('@/components/General/SimpleSelect'),
    ItemChip: () => import('@/components/General/ItemChip'),
    DeleteModal: () => import('@/components/General/DeleteModal'),
    Printer: () => import('@/assets/icons/printer2.svg'),
    Trash: () => import('@/assets/icons/trash2.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    HelpCircle: () => import('@/assets/icons/help-circle.svg')
  },
  props: {
    form: Object,
    clinic: Object,
    index: Number,
    documentOptions: Array
  },

  data() {
    return {
      validate: false,
      loading: false,
      pageFormats: [
        { label: 'A4', value: 'A4' },
        { label: 'A5', value: 'A5' }
      ],
      fontSizes: [
        { label: '100%', value: 100 },
        { label: '90%', value: 90 },
        { label: '80%', value: 80 },
        { label: '70%', value: 70 },
        { label: '60%', value: 60 },
        { label: '50%', value: 50 }
      ],
      orietations: [
        { label: 'Vertical', value: 'P' },
        { label: 'Horizontal', value: 'L' }
      ],
      pageSizes: {
        A4: { width: 210, height: 297 },
        A5: { width: 148, height: 210 }
      },
      documentInputLimit: 4
    }
  },
  computed: {
    maxVerticalMargin() {
      return this.pageSizes[this.form.format].height * 0.3
    },
    maxHorizontalMargin() {
      return this.pageSizes[this.form.format].width * 0.3
    }
  },
  methods: {
    async handleOnSave() {
      if (!this.isValid()) return
      const data = { ...this.form, clinic_id: this.clinic.id }
      this.loading = true
      try {
        if (this.form.id) {
          await this.api.updatePrintSettings(this.form.id, data)
        } else {
          await this.api.createPrintSettings(data)
        }
        this.$emit('newSettingCreated')
        this.loading = false
        this.$toast.success('Configurações salvas com sucesso')
      } catch (err) {
        this.loading = false
        this.$toast.error(err.message)
      }
    },
    async confirmDelete(confirm) {
      this.$bvModal.hide('delete-modal')
      if (confirm) {
        if (this.form.id) {
          this.loading = true
          await this.api.deletePrintSettings(this.form.id)
        }
        this.$emit('deleteSelected', this.index)
        this.loading = false
      }
    },
    async handleOnDelete() {
      this.$bvModal.show('delete-modal')
    },
    removeDocument(document) {
      const index = this.form.documents.indexOf(document)
      if (index > -1) {
        this.form.documents.splice(index, 1)
      }
    },
    isValid() {
      this.validate = true
      return (
        this.form.format &&
        this.form.margin_left &&
        this.form.margin_left <= this.maxHorizontalMargin &&
        this.form.margin_top &&
        this.form.margin_top <= this.maxVerticalMargin &&
        this.form.margin_right &&
        this.form.margin_right <= this.maxHorizontalMargin &&
        this.form.margin_bottom &&
        this.form.margin_bottom <= this.maxVerticalMargin
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-wrapper {
  border-left: 1px solid var(--neutral-200);
  padding-left: 10px;
}

.icon {
  cursor: pointer;
  margin: 0 10px 0 10px;
  &.trash {
    fill: none;
    stroke: var(--neutral-200);
  }

  &.printer {
    fill: none;
    stroke: var(--neutral-200);
  }
}
.help-icon {
  width: 14px;
  margin-bottom: 4px;
}
.error {
  border-color: var(--states-error);
  color: var(--states-error);
}
</style>
